import React from 'react';
import "./Footer.scss";
import {Link} from "react-router-dom";
import MailingForm from "./MailingForm";

const Footer = () => {
  return (
      <div className="Footer">
        <div className="main">
          <div className="links">
            <div>
              <h6>Capabilities</h6>
              <Link to="/capabilities/xdr-soc">XDR+SOC</Link>
              <Link to="/capabilities/va-vt-vms">VA/VT+VMS</Link>
              <Link to="/capabilities/observability">Observability</Link>
            </div>
            <div>
              <h6>Solutions</h6>
              <Link to="/solutions/finance">Financial Services</Link>
              <Link to="/solutions/healthcare">Healthcare</Link>
              <Link to="/solutions/systems">OT and IOT Systems</Link>
              <Link to="/solutions/software">Software and SaaS</Link>
            </div>
            <div className="contact">
              <h6>Contact us</h6>
              <div>
                <span className="material-icons">&#xe0c8;</span>
                <div>
                  <p>18 Howard Road</p>
                  <p>#07-04 Novelty Biz Centre</p>
                  <p>Singapore 369585</p>
                </div>
              </div>
              <div>
                <span className="material-icons">&#xe0b0;</span>
                <a href="tel:+6566791568" className="phone">+65 6679 1568</a>
              </div>
              <div>
                <span className="material-icons">&#xe158;</span>
                <a href="mailto:response@icyber.com.sg" className="mail">response@atsoc.com.sg</a>
              </div>
            </div>
          </div>
          <MailingForm />
          <div className="form-links">
            <Link to="/contact?form=demo">Request a Demo</Link>
            <Link to="/contact?form=health-check">Free Cyber Health Check</Link>
          </div>
          <hr/>
          <div className="bottom">
            <p>Copyright &copy; {new Date().getFullYear()} Infinity Cybersec Singapore. All Rights Reserved.</p>
            <div>
              <Link to="/legal">Legal Information</Link>
              <pre> | </pre>
              <Link to="/terms-of-use">Terms of use</Link>
              <pre> | </pre>
              <Link to="/personal-data-protection-policy">Personal Data Protection Policy</Link>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Footer;