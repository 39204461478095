import React, {useEffect} from 'react';
import "./Legal.scss";

const Legal = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
      <div className="Legal">
        <h1>Legal Information</h1>
        <div>
          <p>INFINITY CYBERSEC PTE. LTD.</p>
          <p>UEN/GST: 201922738M</p>
        </div>
        <div>
          <p>REGISTERED ADDRESS</p>
          <p>18 HOWARD ROAD</p>
          <p>#07-04 NOVELTY BIZ CENTRE</p>
          <p>SINGAPORE 369585</p>
        </div>
      </div>
  );
};

export default Legal;