import React, {useEffect} from 'react';
import "./Home.scss";
import Banner from "../components/Banner";
import {Link} from "react-router-dom";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CloudIcon from '@mui/icons-material/Cloud';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import HeathCheckImage from "../images/health-check.png"
import HomeBanner from "../images/home-banner.webp";


const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <div className="Home">
        <Banner>
          <div className="banner-item home">
            <div>
              <h1>ATSOC</h1>
              <h2>Your trusted Managed Security Platform</h2>
              <p>ATSOC offers customised and integrated security solutions for your organisation.</p>
              <p>Defend your business against cyber threats today with ATSOC.</p>
              <div className="links">
                <Link to="/contact?form=demo">Request a Demo</Link>
                <Link to="/contact?form=health-check">Free Cyber Health Check</Link>
              </div>
            </div>
            <img src={HomeBanner} alt="" />
          </div>
        </Banner>
        <div className="main">
          <div className="services">
            <h1>Capabilities</h1>
            <p>ATSOC delivers industry leading services that caters to all types of environments and organisational architectures, with a cloud-based approach for centralised threat monitoring and detection.</p>
            <div className="services-grid">
              <Link to="/capabilities/xdr-soc?section=how-it-works">
                <h2>24/7 Monitoring</h2>
                <p>Be assured that all your systems are secured and monitored at all times.</p>
                <button>Learn more</button>
              </Link>
              {/*<Link to="/services?section=managed">*/}
              {/*  <h2>Managed Services</h2>*/}
              {/*  <p>Oversee all your managed systems through our Security Operations Centre.</p>*/}
              {/*  <button>Learn more</button>*/}
              {/*</Link>*/}
              <Link to="/capabilities/xdr-soc?section=respond-to-threats">
                <h2>Incident Response</h2>
                <p>Immediate response to any suspicious activity detected by our advanced monitoring systems.</p>
                <button>Learn more</button>
              </Link>
              {/*<Link to="/services?section=network">*/}
              {/*  <h2>Network Monitoring</h2>*/}
              {/*  <p>Discover network health and security issues through analysis of traffic logs.</p>*/}
              {/*  <button>Learn more</button>*/}
              {/*</Link>*/}
              {/*<Link to="/services?section=cloud">*/}
              {/*  <h2>Cloud Security</h2>*/}
              {/*  <p>Ensure the same level of security with public and private cloud deployments.</p>*/}
              {/*  <button>Learn more</button>*/}
              {/*</Link>*/}
              <Link to="/capabilities/observability">
                <h2>Automation</h2>
                <p>Manage large scale environments reliably with automated deployments and administration.</p>
                <button>Learn more</button>
              </Link>
              {/*<Link to="/services?section=governance">*/}
              {/*  <h2>Governance and Compliance</h2>*/}
              {/*  <p>Adherence to organisational and industry standard governance policies to ensure best practices.</p>*/}
              {/*  <button>Learn more</button>*/}
              {/*</Link>*/}
              {/*<Link to="/services?section=consultancy">*/}
              {/*  <h2>Consultancy</h2>*/}
              {/*  <p>Plan your security solutions together with our team of experienced consultants.</p>*/}
              {/*  <button>Learn more</button>*/}
              {/*</Link>*/}
              {/*<Link to="/services?section=maintenance">*/}
              {/*  <h2>Maintenance</h2>*/}
              {/*  <p>Continued support of security infrastructure post deployment.</p>*/}
              {/*  <button>Learn more</button>*/}
              {/*</Link>*/}
            </div>
          </div>
          <div className="benefits">
            <h1>Benefits of ATSOC</h1>
            <p>ATSOC's non-proprietary, cloud-native solutions help you deliver comprehensive, scalable, and affordable security outcomes with the flexibility and portability of cloud.</p>
            <div className="benefits-grid">
              <div>
              <LockOpenIcon/>
                <h2>Non-proprietary technology and a zero lock-in guarantee</h2>
                <p>Our solutions prefer open source software and open frameworks, and cloud-native implementations freeing you from conventional provider lock-in.</p>
              </div>
              <div>
                <CloudIcon/>
                <h2>Cloud-native infrastructure-as-code deployment model</h2>
                <p>Our IaC-based continuous deployment model easily integrates into your existing DevOps pipeline, or we can manage the stack for you.</p>
              </div>
              <div>
                <ChatBubbleIcon/>
                <h2>Consultative, enablement-focused white glove service</h2>
                <p>We aim to empower your business by making security and compliance accessible, understandable, and easy to implement and maintain.</p>
              </div>
            </div>
          </div>
          <div className="fusion">
            <h1>Modern DevSecOps and Cyber Fusion Ready</h1>
            <p>ATSOC's solutions are designed to enable and reinforce modern DevSecOps practices and our platform is the perfect medium for cyber fusion centre integration.</p>
          </div>
          <div className="health-check">
            <img src={HeathCheckImage} alt=""/>
            <div>
              <h1>Start with a Free Cyber Health Check</h1>
              <p>Let us provide you with a free, no-obligation consultation and report — giving you a heads-up on your current security posture with areas of strength and any areas of vulnerability, all clearly indicated and graded.</p>
              <Link to="/contact?form=health-check">Contact Us</Link>
            </div>
          </div>
          {/*<div className="mission-vision">*/}
          {/*  <div>*/}
          {/*    <h2>OUR MISSON</h2>*/}
          {/*    <p>As the name ATSOC, we are here to provide security operation solutions, 24/7 monitoring and prevent losses of your business and data.</p>*/}
          {/*  </div>*/}
          {/*  <div>*/}
          {/*    <h2>OUR VISION</h2>*/}
          {/*    <p>To be a ONE STOP SECURITY PROVIDER that our clients can trust and rely on.</p>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="expertise">*/}
          {/*  <h2>EXPERTISE AND SKILLSETS</h2>*/}
          {/*  <div>*/}
          {/*    <Link to="/services?section=monitoring">24/7 Monitoring</Link>*/}
          {/*    <Link to="/services?section=managed">Managed Services</Link>*/}
          {/*    <Link to="/services?section=incident">Incident Response</Link>*/}
          {/*    <Link to="/services?section=network">Network Monitoring</Link>*/}
          {/*    <Link to="/services?section=cloud">Cloud Security</Link>*/}
          {/*    <Link to="/services?section=automation">Automation</Link>*/}
          {/*    <Link to="/services?section=governance">Governance and Compliance</Link>*/}
          {/*    <Link to="/services?section=consultancy">Consultancy</Link>*/}
          {/*    <Link to="/services?section=maintenance">Maintenance</Link>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
  );
};

export default Home;