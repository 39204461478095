import React, {useEffect} from 'react';
import "./Terms.scss";

const Terms = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
      <div className="Terms">
        <h1>Terms of Use</h1>
        <p>Please read these terms of use ("Terms") carefully. A reference to this "website" is to icyber.com including but not limited to the information, text, images, links, sound, animations, interfaces, artwork, computer codes, and graphics that may be displayed herein ("Materials"). By accessing this website, you agree to be bound by the Terms below. If you do not agree to the Terms, do not access this website. The Terms shall also apply to the corporate brochure, e-newsletter and any other communications materials produced by INFINITY CYBERSEC PTE. LTD. ("ICYBER")("Corporate Materials"). Persons who use, have access or subscribe to any of these Corporate Materials agree to, and are bound by the Terms below to the extent applicable.</p>
        <h2>Disclaimer</h2>
        <p>1.1 All information provided in this website is for information purposes only. Notwithstanding any language to the contrary, nothing contained in or through the website constitutes, nor is intended to constitute an offer, inducement, promise or contract of any kind.</p>
        <p>1.2 This website and the Materials are provided "as is", "as available", and is subject to change, interruption, suspension or termination for any reason without notice. ICYBER and its group of companies do not warrant the accuracy, adequacy or completeness of this website and/or the Materials and expressly disclaims liability for errors or omissions in the Materials. No warranty of any kind, implied, express or statutory, including but not limited to the warranties of non-infringement of third party rights, title, merchantability, satisfactory quality and/or fitness for a particular purpose, use or application, is given in conjunction with the Materials and/or this website.</p>
        <p>1.3 ICYBER does not warrant that any of the Materials or that this website will be provided uninterrupted or free from errors or that any identified defect will be corrected; further, no warranty is given that this website and the Materials are free from any virus or other malicious, destructive or corrupting code, programme or macro.</p>
        <h2>Intellectual Property</h2>
        <p>2.1 The copyright in this website is owned by ICYBER or its licensors. No part or parts hereof including any materials may be reproduced, distributed, adapted, modified, republished, displayed, broadcast, hyperlinked, framed or transmitted in any manner or by any means or stored in an information retrieval system or in any other manner except with the prior written permission of ICYBER, and express attribution to ICYBER. However, you may download and print the Materials on this website for personal, non-commercial use only provided you do not modify the Materials and that you retain all copyright and other proprietary notices contained in the Materials. You also may not, without the prior written permission of ICYBER, insert a hyperlink to this website on any other website or "mirror" any Material contained on this website on any other server.</p>
        <p>2.2 The trade marks, service marks and logo ("Trade Marks") used and displayed on this website are registered and unregistered Trade Marks of ICYBER or its licensors. Nothing on this website should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of the Trade Marks displayed on this website, without the prior written permission of ICYBER or the relevant licensors. The name of ICYBER or the Trade Marks displayed on this website may not be used in any way, including in advertising or publicity pertaining to the distribution of Materials on this website, without prior written permission of ICYBER. ICYBER prohibits use of the Trade Marks displayed on this website as a "hot" link or in-link to any other site unless establishment of such a link is approved in advance by ICYBER in writing.</p>
        <h2>Information Provided to Us</h2>
        <p>3.1 You agree that all information and/or particulars sent or submitted by you to ICYBER in relation to your access of this website is non-confidential and non-proprietary unless otherwise expressly indicated by you. You further undertake not to submit through this website any information and/or other materials that are or may be offensive, illegal or which may not be lawfully disseminated under the laws of Singapore or any other country.</p>
        <h2>Exclusion of Liability</h2>
        <p>4.1 ICYBER shall in no event be liable for any damages, loss or expense including without limitation, direct, indirect, special, consequential, exemplary or punitive damage, or economic loss however caused arising from or in connection with: (i) any access, use or the inability to access or use this website, or reliance on the Materials and/or any information in this website; (ii) any system, server or connection failure, error, omission, interruption, deletion, defect, delay in operation or transmission, or computer virus; and (iii) any use of or access to any other website linked to this website, even if ICYBER or its agents or employees are advised of the possibility of such damages, losses and/or expenses.</p>
        <p>4.2 At certain places in this website, links to external websites can be accessed. Unless specified by ICYBER, any hyperlinks to any such other websites are not a verification of the websites or an endorsement of any views expressed or products or services offered therein. ICYBER is not responsible for the contents or reliability of any of such websites and does not control, monitor or guarantee the information contained in such websites. ICYBER disclaims all responsibility and liability for the use of such hyperlinked websites, which should only be accessed at your own risk. ICYBER is not responsible for the privacy policy of other websites. This exclusion clause shall take effect to the fullest extent permitted by law.</p>
        <p>4.3 Similarly, there may be other websites which may contain hyperlinks to this website. ICYBER is not responsible for the contents of those websites and shall not be liable for any damages however caused arising from the contents of those websites.</p>
        <h2>E-mail Communication and e-Newsletter</h2>
        <p>5.1 If you choose to identify yourself by sending an e-mail, or subscribing to our e-newsletter, the information you send may be entered into our electronic database and may be reviewed by ICYBER's staff. Your information will not be given to any other organisation.</p>
        <p>5.2 If you subscribe to our e-newsletter, you may unsubscribe at any time by clicking on the "Unsubscribe" button at the bottom of the e-newsletter.</p>
        <p>5.3 Please be aware that e-mail and other Internet communication channels may not be secure against interception, and we cannot guarantee the security or confidentiality of any of the information transmitted. If your communication is personal or sensitive, you may prefer to send it by postal mail or by hand instead or contact us by telephone.</p>
        <h2>General</h2>
        <p>6.1 ICYBER reserves the right to revise these Terms at any time and visitors are deemed to be aware of and bound by any changes to these Terms upon publication on our website.</p>
        <p>6.2 ICYBER reserves the right to seek all remedies available at law and in equity for violations of these Terms, including the right to block access from a particular Internet address to our website and/or e-newsletter.</p>
        <p>6.3 By accessing this website, you agree that Singapore law shall govern such access and/or use as well as the above terms and you agree to submit to the non-exclusive jurisdiction of the Singapore courts.</p>
      </div>
  );
};

export default Terms;