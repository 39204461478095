import React, {useEffect, useState} from 'react';
import "./Observability.scss";
import Banner from "../../components/Banner";
import ObservabilityBanner from "../../images/observability-banner.webp";
import CrawlIcon from "../../images/crawl-icon.png";
import ScanIcon from "../../images/scan-icon.png";
import EasyIcon from "../../images/easy-icon.png";
import EvidenceIcon from "../../images/evidence-icon.png";
import PolicyIcon from "../../images/policy-icon.png";
import CodeIcon from "../../images/code-icon.png";

const Observability = () => {

  const tabs = ["Overview"];
  const [selectedTab, setSelectedTag] = useState("Overview");
  const tabsContent = {
    "Overview": [
      {
        "title": "Cloud-Native SIEM",
        "content": "ATSOC deploys a cloud-native, fully managed, petabyte-scale SIEM solution into your account(s) that detect and correlate security incidents from your event sources."
      },
      {
        "title": "Custom Log Sources",
        "content": "ATSOC ingests logs and event data from sources throughout your environment so that events can be analysed and correlated. This includes native, out-of-the box connectors and the ability to build the required parsers and rules to support custom log sources."
      }
    ],
    "Detect": [
      {
        "title": "Crawl Client and Server Endpoints",
        "content": "The ATSOC Data Security agent will inspect the file systems on your Windows, Linux, and macOS endpoints."
      },
      {
        "title": "Search Apps and Databases",
        "content": "Leverage out-of-the-box integration with common applications and search data stored in any database with an ODBC-compatible driver."
      }
    ]
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <div className="Observability">
        <Banner>
          <div className="banner-item">
            <div>
              <h2>Observability</h2>
              <p>Enhance observability of your systems across multiple architectures and environments to increase monitoring capabilities.</p>
            </div>
            <img src={ObservabilityBanner} alt=""/>
          </div>
        </Banner>
        <div className="main">
          <div className="how-it-works">
            <p>HOW IT WORKS</p>
            <h1>Collection of logs across multiple sources</h1>
            <div className="how-it-works-graphic">
              <div className="tabs">
                {tabs.map(tab => <p
                    onClick={() => setSelectedTag(tab)}
                    className={selectedTab === tab ? "active": ""}
                >{tab}</p>)}
              </div>
              <div className="tab-content">
                {tabsContent[selectedTab].map(item => <div>
                  <h2>{item["title"]}</h2>
                  <p>{item["content"]}</p>
                </div>)}
              </div>
            </div>
          </div>
          <div className="benefits">
            <p>BENEFITS</p>
            <h1>Automated active collection of data</h1>
            <div className="benefits-grid">
              <div>
                <img src={CrawlIcon} alt=""/>
                <h2>Crawl Data in Cloud Platforms</h2>
                <p>The ATSOC Data Security agent can also be configured to scan data stored in cloud collaboration and storage platforms, such as G Suite, Microsoft 365, Exchange, and more.</p>
              </div>
              <div>
                <img src={ScanIcon} alt=""/>
                <h2>Scan Cloud Storage and Source Control</h2>
                <p>Scan cloud data repositories like Amazon S3, Box, Dropbox and more. Additionally, scan other git-compatible source control repositories to ensure no sensitive data is embedded in the code of your applications.</p>
              </div>
              <div>
                <img src={EasyIcon} alt=""/>
                <h2>Easy-to-Use Integrations</h2>
                <p>Automate evidence collection and policy enforcement with out-of-the-box, configurable customisations.</p>
              </div>
              <div>
                <img src={EvidenceIcon} alt=""/>
                <h2>Automated Evidence Collection</h2>
                <p>Leverage ATSOC's out-of-the-box integrations to collect evidence from scoped systems. You can also use our SDKs to develop your own integrations or work with ATSOC to build them for you.</p>
              </div>
              <div>
                <img src={PolicyIcon} alt=""/>
                <h2>Automated Policy Checks</h2>
                <p>Implement automated policy checks to ensure that your organisation's policies are properly enforced across all aspects of your IT and operational environments.</p>
              </div>
              <div>
                <img src={CodeIcon} alt=""/>
                <h2>Infrastructure-as-Code Policy Enforcement</h2>
                <p>Overlay your policies on top of compatible infrastructure-as-code modules to create inherently compliant building blocks and ensure your policy implementations are always up-to-date.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Observability;