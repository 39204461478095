import React from 'react';
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./Banner.scss";

const Banner = (props) => {

  const {children} = props;

  return (
      <Carousel
          className="Banner"
          autoPlay
          emulateTouch
          infiniteLoop
          showArrows
          stopOnHover={false}
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={50}
          interval={10000}
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
      >
        {children}
      </Carousel>
  );
};

export default Banner;