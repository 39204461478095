import React, {useEffect, useState} from 'react';
import {Link, NavLink} from "react-router-dom";
import AtsocLogo from "../images/atsoc-logo-transparent.png";
import {Menu, MenuOpen} from "@material-ui/icons";
import "./Header.scss";

const Header = () => {

  const [menuOpened, setMenuOpened] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState("");

  const scrollListener = () => {
    setScroll(window.scrollY > 20);
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollListener);
    return () => window.removeEventListener("scroll", scrollListener);
  });

  return (
      <div className={"Header" + (scroll || menuOpened  ? " scroll": "")}>
        <div className="nav">
          <div className="menu-button"
               onClick={() => setMenuOpened(!menuOpened)}
               //onBlur={() => setMenuOpened(false)}
               tabIndex={-1}
          >
            {menuOpened ? <MenuOpen/>: <Menu/>}
          </div>
          <Link className="logo" to="/"><img src={AtsocLogo} alt="Home"/></Link>
          <div className={"nav-links" + (menuOpened ? " opened": "")}>
            <NavLink to="/" onClick={() => setMenuOpened(false)} end>Home</NavLink>
            <div onClick={() => expandedMenu !== "capabilities" ? setExpandedMenu("capabilities") : setExpandedMenu("")}>
              <p>Capabilities</p>
              <div className={expandedMenu === "capabilities" ? "expand" : ""}>
                <NavLink to="/capabilities/xdr-soc" onClick={() => setMenuOpened(false)}>XDR+SOC</NavLink>
                <NavLink to="/capabilities/va-pt-vms" onClick={() => setMenuOpened(false)}>VA/PT+VMS</NavLink>
                <NavLink to="/capabilities/observability" onClick={() => setMenuOpened(false)}>Observability</NavLink>
              </div>
            </div>
            <div onClick={() => expandedMenu !== "solutions" ? setExpandedMenu("solutions") : setExpandedMenu("")}>
              <p>Solutions</p>
              <div className={expandedMenu === "solutions" ? "expand" : ""}>
                <NavLink to="/solutions/finance" onClick={() => setMenuOpened(false)}>Financial Services</NavLink>
                <NavLink to="/solutions/healthcare" onClick={() => setMenuOpened(false)}>Healthcare</NavLink>
                <NavLink to="/solutions/systems" onClick={() => setMenuOpened(false)}>OT and IoT Systems</NavLink>
                <NavLink to="/solutions/software" onClick={() => setMenuOpened(false)}>Software and SaaS</NavLink>
              </div>
            </div>
            <a href="https://atsoc.co/">Cyber News</a>
            <NavLink to="/company" onClick={() => setMenuOpened(false)}>Company</NavLink>
            <NavLink to="/contact" onClick={() => setMenuOpened(false)}>Contact us</NavLink>
          </div>
        </div>
      </div>
  );
};

export default Header;