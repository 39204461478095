import React, {useEffect, useRef, useState} from 'react';
import "./XdrSoc.scss";
import Banner from "../../components/Banner";
import XdrBanner from "../../images/xdr-banner.webp";
import DetectIcon from "../../images/detect-icon.png";
import PrioritiseIcon from "../../images/prioritise-icon.png";
import RespondIcon from "../../images/respond-icon.png";
import AutomationIcon from "../../images/automation-icon.png";
import FlexibleIcon from "../../images/flexible-icon.png";
import LockIcon from "../../images/lock-icon.png";
import DevopsIcon from "../../images/devops-icon.png";
import {useSearchParams} from "react-router-dom";

const XdrSoc = () => {

  const anchors = {
    "how-it-works": useRef(),
    "respond-to-threats": useRef()
  }
  const [searchParams] = useSearchParams();
  const tabs = ["Overview", "Detect", "Automate", "Respond", "Protect"];
  const [selectedTab, setSelectedTag] = useState("Overview");
  const tabsContent = {
    "Overview": [
      {
        "title": "Cloud-Native SIEM",
        "content": "ATSOC deploys a cloud-native, fully managed, petabyte-scale SIEM solution into your account(s) that detect and correlate security incidents from your event sources."
      },
      {
        "title": "Custom Log Sources",
        "content": "ATSOC ingests logs and event data from sources throughout your environment so that events can be analysed and correlated. This includes native, out-of-the box connectors and the ability to build the required parsers and rules to support custom log sources."
      }
    ],
    "Detect": [
      {
        "title": "Hybrid Log Source Support",
        "content": "Comprehensive log management coverage using a combination of native data connectors and the ATSOC Log Collector."
      }
    ],
    "Automate": [
      {
        "title": "Automated SOAR Capabilities",
        "content": "Automated incident response using a versatile, easy-to-use workflow engine with included notification and auto-remediation service hooks."
      }
    ],
    "Respond": [
      {
        "title": "Always-On SOC",
        "content": "Our security operations centre has a team of experts providing around-the-clock monitoring, investigation, and remediation."
      }
    ],
    "Protect": [
      {
        "title": "Fully Integrated Platform",
        "content": "Achieve more comprehensive security outcomes with out-of-the-box integrations across ATSOC's portfolio of security providers and solutions, all in a consolidated, single-panel view."
      }
    ]
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const section = searchParams.get("section");
    if (section)
      setTimeout(() => anchors[section].current.scrollIntoView({behavior: "smooth"}), 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
      <div className="XdrSoc">
        <Banner>
          <div className="banner-item xdr">
            <div>
              <h2>Extended Detection & Response +<br/>Security Operations Centre</h2>
              <p>Continuously detect malicious behaviour and let ATSOC's team of experts guide remediation.</p>
            </div>
            <img src={XdrBanner} alt=""/>
          </div>
        </Banner>
        <div className="main">
          <div className="why">
            <p>WHY XDR+SOC</p>
            <h1>Manage threats and reverse the<br/>damage of exploited weaknesses.</h1>
            <div className="why-grid">
              <div>
                <img src={DetectIcon} alt=""/>
                <h2>Detect malicious behaviour</h2>
                <p>Collect logs and telemetry across your enterprise and cloud environments and leverage ATSOC's robust threat-hunting and alerting library to detect threats.</p>
              </div>
              <div>
                <img src={PrioritiseIcon} alt=""/>
                <h2>Priortise applicable threats</h2>
                <p>Using open source, commercial, and proprietary threat intelligence, the ATSOC platform enriches incoming data to enable smarter, faster determinations of threat levels.</p>
              </div>
              <div ref={anchors["respond-to-threats"]}>
                <img src={RespondIcon} alt=""/>
                <h2>Respond to threats</h2>
                <p>When threats are detected, alerts and incidents are created – you can rely on ATSOC's team of security experts around-the-clock to respond to threats.</p>
              </div>
              <div>
                <img src={AutomationIcon} alt=""/>
                <h2>Save time with automation</h2>
                <p>ATSOC's platform was built to take advantage of advanced AI and machine learning, as well as cloud-native automation engines to make all aspects of the security lifecycle simpler.</p>
              </div>
            </div>
          </div>
          <div className="how-it-works" ref={anchors["how-it-works"]}>
            <p>HOW IT WORKS</p>
            <h1>Cloud-native detection and response with the support of a 24/7 team of cybersecurity experts.</h1>
            <div className="how-it-works-graphic">
              <div className="tabs">
                {tabs.map(tab => <p
                    onClick={() => setSelectedTag(tab)}
                    className={selectedTab === tab ? "active": ""}
                >{tab}</p>)}
              </div>
              <div className="tab-content">
                {tabsContent[selectedTab].map(item => <div>
                  <h2>{item["title"]}</h2>
                  <p>{item["content"]}</p>
                </div>)}
              </div>
            </div>
          </div>
          <div className="benefits">
            <p>BENEFITS</p>
            <h1>Own your valuable data and configurations.<br/>Leave the management to us.</h1>
            <div className="benefits-grid">
              <div>
                <img src={FlexibleIcon} alt=""/>
                <h2>Flexible and Customisable</h2>
                <p>Only subscribe to the features you need. If you decide to in-source some or all of your SOC capabilities, simply scale back your subscription.</p>
              </div>
              <div>
                <img src={LockIcon} alt=""/>
                <h2>Zero Lock-In Guaranteed</h2>
                <p>If you cancel after your initial term, you'll lose ATSOC support and updates, but retain the XDR platform with all of the valuable historical data, tuning, playbooks and automation assets.</p>
              </div>
              <div>
                <img src={DevopsIcon} alt=""/>
                <h2>Future-Proof DevOps Compatibility</h2>
                <p>Integrate security into your DevOps workflows with our provided reference architecture and infrastructure code included.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default XdrSoc;