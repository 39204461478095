
const getOptionsProperty = (options, property) => {
  for (const option of options)
    if (option.startsWith(property))
      return option.substring(property.length + 1);
  return null;
}

const validate = (fields) => {

  const data = {}, errors = {};

  Object.entries(fields).forEach(([field, properties]) => {

    if (properties["type"] === "custom")
      return;

    let {placeholder, validations, value} = properties;
    const required = validations.includes("required");

    // Trim whitespace and collapse consecutive spaces
    if (validations.includes("trim"))
      value = value.trim().replace(/\s+/g, " ");

    // Validate null
    if (value === null || value === "") {
      if (required)
        errors[field] =  placeholder + " is required";
      data[field] = value;
      return;
    }

    // Validate email
    if (validations.includes("email") && !/^[A-Za-z\d.]+@[A-Za-z\d.]+\.[A-Za-z\d]+$/g.test(value)) {
      errors[field] = "Email is invalid";
      return;
    }

    // Validate phone number
    if (validations.includes("phone") && !/[+ \-\d]+/.test(value)) {
      errors[field] = "Phone number is invalid";
      return;
    }

    // Check max length
    const maxLength = getOptionsProperty(validations, "max");
    if (maxLength && value.length > parseInt(maxLength)) {
      errors[field] = fields[field] + " cannot be longer than " + maxLength + " characters";
      return;
    }

    // Check min length
    const minLength = getOptionsProperty(validations, "min");
    if (minLength && value.length < parseInt(minLength)) {
      errors[field] = fields[field] + " cannot be shorter than " + minLength + " characters";
      return;
    }

    // Check within
    const within = getOptionsProperty(validations, "within");
    if (within && !within.split(",").includes(value)) {
      errors[field] = fields[field] + " is an invalid option";
      return;
    }

    data[field] = value;

  });

  return [data, errors];

}

export default validate;