import React, {useEffect} from 'react';
import LicenseLogo from "../images/license-logo.jpg";
import "./Company.scss";
import Banner from "../components/Banner";
import CompanyBanner from "../images/company-banner-2.webp";

const Company = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <div className="Company">
        <Banner>
          <div className="banner-item company">
            <div>
              <h2>Company</h2>
              <p>ATSOC is a product of Infinity Cybersec, a cybersecurity provider in Singapore established in 2019 with an extensive portfolio of completed and ongoing projects for large and small organisations.</p>
            </div>
            <img src={CompanyBanner} alt=""/>
          </div>
        </Banner>
        <div className="main">
          <div className="vision-mission">
            <div>
              <h1>Our Mission</h1>
              <p>To provide effective security operation solutions that help organisations manage cybersecurity risks.</p>
            </div>
            <div>
              <h1>Our Vision</h1>
              <p>We are a ONE STOP SECURITY PROVIDER that our clients can trust and rely on.</p>
            </div>
          </div>
          <div className="licenses">
            <h1>Licenses</h1>
            <p>Infinity Cybersec is licensed by the Cybersecurity Services Regulation Office of Singapore to provide Managed Security Operations Centre Monitoring and Penetration Testing services.</p>
            <div>
              <div>
                <img src={LicenseLogo} alt=""/>
                <h6>Managed Security Operations Centre Monitoring</h6>
                <h6>INFINITY CYBERSEC PTE. LTD.</h6>
                <p>UEN: 201922738M</p>
                <p>License ID: CS/SOC/C-2022-0089</p>
              </div>
              <div>
                <img src={LicenseLogo} alt=""/>
                <h6>Penetration Testing</h6>
                <h6>INFINITY CYBERSEC PTE. LTD.</h6>
                <p>UEN: 201922738M</p>
                <p>License ID: CS/PTS/C-2022-0089</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Company;