import React, {useEffect} from 'react';
import "./Systems.scss";
import Banner from "../../components/Banner";
import IotBanner from "../../images/iot-banner.webp";
import {Link} from "react-router-dom";
import IsoIcon from "../../images/iso-icon.png";
import AicpaIcon from "../../images/aicpa-icon.png";
import NistIcon from "../../images/nist-icon.webp";

const Systems = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <div className="Systems">
        <Banner>
          <div className="banner-item">
            <div>
              <h2>Operational Technology<br/>& IoT Solutions</h2>
              <p>ATSOC's solutions for OT and IoT are designed to integrate modern security practises and tools into your delivery processes.</p>
            </div>
            <img src={IotBanner} alt=""/>
          </div>
        </Banner>
        <div className="main">
          <div className="risk-based-approach">
            <p>RISK-BASED APPROACH</p>
            <h1>OT risk management simplified</h1>
            <div>
              <p>The advent of internet-connected operational tech has dramatically increased the attack surface of your critical systems infrastructure. ATSOC's solutions are designed to leverage industry-leading, network-level inspection of your OT systems to provide prioritised risk mitigation and remediation.</p>
              <div>
                <img src={IsoIcon} alt=""/>
                <img src={AicpaIcon} alt=""/>
                <img src={NistIcon} alt=""/>
              </div>
            </div>
          </div>
          <div className="services-and-products">
            <p>SERVICES AND PRODUCTS</p>
            <h1>Solutions built around our core capabilities</h1>
            <div className="services-and-products-grid">
              <div>
                <h2>XDR+SOC</h2>
                <p>Continuously detect malicious behaviour and let ATSOC's team of experts guide remediation.</p>
                <Link to="/capabilities/xdr-soc">Learn more</Link>
              </div>
              <div>
                <h2>VA/PT+VMS</h2>
                <p>Continuously scan your infrastructure and applications for security weaknesses.</p>
                <Link to="/capabilities/va-pt-vms">Learn more</Link>
              </div>
              <div>
                <h2>Observability</h2>
                <p>Enhance observability of your systems across multiple architectures and environments to increase monitoring capabilities.</p>
                <Link to="/capabilities/observability">Learn more</Link>
              </div>
            </div>
          </div>
          <div className="contact">
            <p>Contact us to find out more about our packages and pricing.</p>
            <Link to="/contact">Contact Us</Link>
          </div>
        </div>
      </div>
  );
};

export default Systems;