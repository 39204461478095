import React, {useEffect, useRef} from 'react';
import "./Contact.scss";
import Banner from "../components/Banner";
import ContactForm from "../components/ContactForm";
import ContactBanner from "../images/contact-banner-2.webp";
import {useSearchParams} from "react-router-dom";

const Contact = () => {

  const formRef = useRef();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (searchParams.get("form"))
      setTimeout(() => formRef.current.scrollIntoView({behavior: "smooth"}), 1);
  }, [searchParams]);

  return (
      <div className="Contact">
        <Banner>
          <div className="banner-item contact">
            <div>
              <h2>Contact us</h2>
              <p>If you have any questions about our services or products, please contact us via email, hotline or fill up the form below and we will get back to you shortly.</p>
            </div>
            <img src={ContactBanner} alt="" />
          </div>
        </Banner>
        <div className="main">
          <div ref={formRef}>
            <ContactForm />
          </div>
          <div className="details-map">
            <div className="details">
              <h2>Infinity Cybersec Pte Ltd</h2>
              <p>UEN/GST: 201922738M</p>
              <div>
                <p>Address:</p>
                <p>18 Howard Road</p>
                <p></p>
                <p>#07-04 Novelty Biz Centre</p>
                <p></p>
                <p>Singapore 369585</p>
                <p></p>
                <p></p>
                <p>Phone:</p>
                <p>+65 9772 6123</p>
                <p>Email:</p>
                <p>response@atsoc.com.sg</p>
              </div>
            </div>
            <iframe
                title="map"
                className="map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3988.7369807945797!2d103.885854!3d1.333988!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da172d31e31ba1%3A0x6b70412eeb822354!2sNovelty%20Bizcentre!5e0!3m2!1sen!2ssg!4v1667001558313!5m2!1sen!2ssg">
            </iframe>
          </div>
        </div>
      </div>
  );
};

export default Contact;