
export const post = async (path, data) => {
  const response = await fetch(process.env.REACT_APP_SERVER_URL + path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "include",
    body: JSON.stringify(data)
  });
  return {
    status: response.status,
    result: await response.json()
  }
}

export const put = async (path, data) => {
  const response = await fetch(process.env.REACT_APP_SERVER_URL + path, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "include",
    body: JSON.stringify(data)
  });
  return {
    status: response.status,
    result: await response.json()
  }
}

export const get = async (path) => {
  const response = await fetch(process.env.REACT_APP_SERVER_URL + path, {method: "GET", credentials: "include"});
  return {
    status: response.status,
    result: await response.json()
  }
}