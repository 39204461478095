import React, {useEffect} from 'react';
import "./Software.scss";
import Banner from "../../components/Banner";
import SoftwareBanner from "../../images/software-banner.webp";
import {Link} from "react-router-dom";
import NistIcon from "../../images/nist-icon.webp";
import GdprIcon from "../../images/gdpr-icon.png";
import PciIcon from "../../images/pci-icon.svg";

const Software = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <div className="Software">
        <Banner>
          <div className="banner-item">
            <div>
              <h2>Software and<br/>SaaS Solutions</h2>
              <p>ATSOC's solutions enable modern continuous delivery pipelines with cybersecurity and enterprise risk management built-in.</p>
            </div>
            <img src={SoftwareBanner} alt=""/>
          </div>
        </Banner>
        <div className="main">
          <div className="risk-based-approach">
            <p>RISK-BASED APPROACH</p>
            <h1>Unlock your team's potential<br/>with modern DevSecOps practises.</h1>
            <div>
              <p>By integrating security throughout the DevOps infinity loop, developers get faster feedback enabling them to address security weaknesses in context. With weaknesses being addressed further "left", automated and hybrid risk management controls can be integrated in your CI/CD pipelines allowing for transparent, seamless risk-based implementations.</p>
              <div>
                <img src={NistIcon} alt=""/>
                <img src={GdprIcon} alt=""/>
                <img src={PciIcon} alt=""/>
              </div>
            </div>
          </div>
          <div className="services-and-products">
            <p>SERVICES AND PRODUCTS</p>
            <h1>Solutions built around our core capabilities</h1>
            <div className="services-and-products-grid">
              <div>
                <h2>XDR+SOC</h2>
                <p>Continuously detect malicious behaviour and let ATSOC's team of experts guide remediation.</p>
                <Link to="/capabilities/xdr-soc">Learn more</Link>
              </div>
              <div>
                <h2>VA/PT+VMS</h2>
                <p>Continuously scan your infrastructure and applications for security weaknesses.</p>
                <Link to="/capabilities/va-pt-vms">Learn more</Link>
              </div>
              <div>
                <h2>Observability</h2>
                <p>Enhance observability of your systems across multiple architectures and environments to increase monitoring capabilities.</p>
                <Link to="/capabilities/observability">Learn more</Link>
              </div>
            </div>
          </div>
          <div className="contact">
            <p>Contact us to find out more about our packages and pricing.</p>
            <Link to="/contact">Contact Us</Link>
          </div>
        </div>
      </div>
  );
};

export default Software;