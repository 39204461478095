import React, {useEffect} from 'react';
import "./Financial.scss";
import Banner from "../../components/Banner";
import FinanceBanner from "../../images/finance-banner.webp";
import {Link} from "react-router-dom";
import PciIcon from "../../images/pci-icon.svg";
import GdprIcon from "../../images/gdpr-icon.png";
import SarbanesIcon from "../../images/sarbanes-icon.webp";

const Financial = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <div className="Financial">
        <Banner>
          <div className="banner-item">
            <div>
              <h2>Financial Solutions</h2>
              <p>From adoption of RegTech to implementing blockchain-enabled data workflows, ATSOC's FinTech solution enables your team to innovate securely.</p>
            </div>
            <img src={FinanceBanner} alt=""/>
          </div>
        </Banner>
        <div className="main">
          <div className="risk-based-approach">
            <p>RISK-BASED APPROACH</p>
            <h1>FinTech-specific risk expertise</h1>
            <div>
              <p>From country-specific regulatory requirements to international best practises, ATSOC will help diagnose the risks associated with your specific technology footprint and provide solutions to address, mitigate, and monitor these risks.</p>
              <div>
                <img src={PciIcon} alt=""/>
                <img src={GdprIcon} alt=""/>
                <img src={SarbanesIcon} alt=""/>
              </div>
            </div>
          </div>
          <div className="services-and-products">
            <p>SERVICES AND PRODUCTS</p>
            <h1>Solutions built around our core capabilities</h1>
            <div className="services-and-products-grid">
              <div>
                <h2>XDR+SOC</h2>
                <p>Continuously detect malicious behaviour and let ATSOC's team of experts guide remediation.</p>
                <Link to="/capabilities/xdr-soc">Learn more</Link>
              </div>
              <div>
                <h2>VA/PT+VMS</h2>
                <p>Continuously scan your infrastructure and applications for security weaknesses.</p>
                <Link to="/capabilities/va-pt-vms">Learn more</Link>
              </div>
              <div>
                <h2>Observability</h2>
                <p>Enhance observability of your systems across multiple architectures and environments to increase monitoring capabilities.</p>
                <Link to="/capabilities/observability">Learn more</Link>
              </div>
            </div>
          </div>
          <div className="contact">
            <p>Contact us to find out more about our packages and pricing.</p>
            <Link to="/contact">Contact Us</Link>
          </div>
        </div>
      </div>
  );
};

export default Financial;