import React, {useEffect} from 'react';
import "./Healthcare.scss";
import Banner from "../../components/Banner";
import HealthcareBanner from "../../images/healthcare-banner.webp";
import {Link} from "react-router-dom";
import HitrustIcon from "../../images/hitrust-icon.png";
import GdprIcon from "../../images/gdpr-icon.png";
import NistIcon from "../../images/nist-icon.webp";

const Healthcare = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <div className="Healthcare">
        <Banner>
          <div className="banner-item">
            <div>
              <h2>Healthcare Solutions</h2>
              <p>From securing PHI to maintaining research compliance, ATSOC's healthcare solution achieves security and compliance without hindering your workflow.</p>
            </div>
            <img src={HealthcareBanner} alt=""/>
          </div>
        </Banner>
        <div className="main">
          <div className="risk-based-approach">
            <p>RISK-BASED APPROACH</p>
            <h1>Healthcare-specific risk expertise</h1>
            <div>
              <p>Each healthcare organisation has a unique mix of risk attributes. For some patient privacy is critical. For others, protecting the intellectual property and integrity of their research is the foremost concern. ATSOC will work with you to build solution that addresses the specific risk priorities of your organisation.</p>
              <div>
                <img src={HitrustIcon} alt=""/>
                <img src={GdprIcon} alt=""/>
                <img src={NistIcon} alt=""/>
              </div>
            </div>
          </div>
          <div className="services-and-products">
            <p>SERVICES AND PRODUCTS</p>
            <h1>Solutions built around our core capabilities</h1>
            <div className="services-and-products-grid">
              <div>
                <h2>XDR+SOC</h2>
                <p>Continuously detect malicious behaviour and let ATSOC's team of experts guide remediation.</p>
                <Link to="/capabilities/xdr-soc">Learn more</Link>
              </div>
              <div>
                <h2>VA/PT+VMS</h2>
                <p>Continuously scan your infrastructure and applications for security weaknesses.</p>
                <Link to="/capabilities/va-pt-vms">Learn more</Link>
              </div>
              <div>
                <h2>Observability</h2>
                <p>Enhance observability of your systems across multiple architectures and environments to increase monitoring capabilities.</p>
                <Link to="/capabilities/observability">Learn more</Link>
              </div>
            </div>
          </div>
          <div className="contact">
            <p>Contact us to find out more about our packages and pricing.</p>
            <Link to="/contact">Contact Us</Link>
          </div>
        </div>
      </div>
  );
};

export default Healthcare;