import React, {useEffect, useState} from 'react';
import "./VaPtVms.scss";
import VaBanner from "../../images/va-banner.webp";
import Banner from "../../components/Banner";
import PenetrationIcon from "../../images/penetration-icon.png";
import MonitoringIcon from "../../images/monitoring-icon.png";
import RemediationIcon from "../../images/remediation-icon.png";
import ReportingIcon from "../../images/reporting-icon.png";
import TargetIcon from "../../images/target-icon.png";
import TieredIcon from "../../images/tiered-icon.png";
import LevelsIcon from "../../images/levels-icon.png";
import ScanningIcon from "../../images/scanning-icon.png";
import IntegrationIcon from "../../images/integration-icon.png";

const VaPtVms = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const tabs = ["Overview", "Detect", "Test"];
  const [selectedTab, setSelectedTag] = useState("Overview");
  const tabsContent = {
    "Overview": [
      {
        "title": "Vulnerability Management (VM)",
        "content": "Discover and manage vulnerabilities and misconfigurations with ATSOC's VA/PT solution."
      }
    ],
    "Detect": [
      {
        "title": "Detect Identify Misconfigurations and Nested Vulnerabilities",
        "content": "Continuously monitor your cloud environments to alert of any misconfigurations and potential security issues. Expose vulnerabilities in your application code and nested dependencies"
      },
      {
        "title": "OS and App Scanning",
        "content": "ATSOC probes your servers for OS-level vulnerabilities. We also probe for installed applications and test for vulnerabilities in the application layer — including containers and nested virtualisation."
      }
    ],
    "Test": [
      {
        "title": "Attack Simulation and Penetration Testing",
        "content": "Gain deeper insights into how a threat actor could exploit the weaknesses in your system across each stage of the kill chain."
      }
    ]
  }

  return (
      <div className="VaPtVms">
        <Banner>
          <div className="banner-item">
            <div>
              <h2>Vulnerability Management</h2>
              <p>Continuously scan and test your environment for security weaknesses.</p>
            </div>
            <img src={VaBanner} alt=""/>
          </div>
        </Banner>
        <div className="main">
          <div className="why">
            <p>WHY VAPT+VMS</p>
            <h1>Discover any vulnerabilities in<br/>your digital infrastructure.</h1>
            <div className="why-grid">
              <div>
                <img src={PenetrationIcon} alt=""/>
                <h2>Vulnerability assessment and penetration testing</h2>
                <p>Identify vulnerabilities and security flaws in order to understand the aggregate level of risk for your organisation and to meet regulatory compliance requirements.</p>
              </div>
              <div>
                <img src={MonitoringIcon} alt=""/>
                <h2>Continuous assessment and monitoring</h2>
                <p>Catch security weaknesses as they occur by monitoring continuously. Newly installed software, configuration changes, and newly discovered vulnerabilities can't wait for your next annual pen test.</p>
              </div>
              <div>
                <img src={RemediationIcon} alt=""/>
                <h2>Vulnerability remediation guidance</h2>
                <p>ATSOC provides effective, tailored, step-by-step guidance for vulnerability remediation and an instant re-scan feedback loop for verifying remediation.</p>
              </div>
              <div>
                <img src={ReportingIcon} alt=""/>
                <h2>Intuitive, comprehensive reporting</h2>
                <p>ATSOC's intuitive reporting helps you understand the bottom-line risks and help you to prioritise the critical risks and integrate the remainder into your typical infrastructure lifecycle.</p>
              </div>
            </div>
          </div>
          <div className="vapt">
            <div>
              <h2>Vulnerability Scanning</h2>
              <p>Continuous scanning of your VM, container, cloud, and on-premise infrastructure and applications for vulnerabilities and best practice policy violations.</p>
            </div>
            <p>+</p>
            <div>
              <h2>Penetration Testing</h2>
              <p>Expert human testing of your security controls with real-world adversarial techniques and an optional remediation workshop to help establish a secure baseline.</p>
            </div>
            <p>=</p>
            <div>
              <h2>VA+PT Complete</h2>
              <p>A combination package of our vulnerability assessment and penetration testing packages with annual subscription discounts on penetration tests.</p>
            </div>
          </div>
          <div className="how-it-works">
            <p>HOW IT WORKS</p>
            <h1>Expose Exploitable Weaknesses</h1>
            <div className="how-it-works-graphic">
              <div className="tabs">
                {tabs.map(tab => <p
                    onClick={() => setSelectedTag(tab)}
                    className={selectedTab === tab ? "active": ""}
                >{tab}</p>)}
              </div>
              <div className="tab-content">
                {tabsContent[selectedTab].map(item => <div>
                  <h2>{item["title"]}</h2>
                  <p>{item["content"]}</p>
                </div>)}
              </div>
            </div>
          </div>
          <div className="benefits">
            <p>BENEFITS</p>
            <h1>Customise Your Solution</h1>
            <div className="benefits-grid">
              <div>
                <img src={TargetIcon} alt=""/>
                <h2>Target What You Need</h2>
                <p>Choose full network or ring-fenced targets for scans, again only paying for what you need.</p>
              </div>
              <div>
                <img src={TieredIcon} alt=""/>
                <h2>Tiered Model</h2>
                <p>Vulnerability, container, and cloud configuration scanning are billed per endpoint in a progressively decreasing tiering model – you’ll only pay for what you need.</p>
              </div>
              <div>
                <img src={LevelsIcon} alt=""/>
                <h2>Multiple Plan Levels</h2>
                <p>Scheduled scans available at the basic tier and continuous scanning available at the professional tier and above.</p>
              </div>
              <div>
                <img src={ScanningIcon} alt=""/>
                <h2>Container Scanning</h2>
                <p>Container scanning includes container registry image scanning as well as runtime analysis and vulnerability validation.</p>
              </div>
              <div>
                <img src={IntegrationIcon} alt=""/>
                <h2>Custom Integrations</h2>
                <p>Custom CMDB and service desk integrations are available at the enterprise level.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default VaPtVms;