import React, {useState} from 'react';
import "./MailingForm.scss";
import {post} from "../utilities/Api";
import validate from "../utilities/Validator";

const MailingForm = () => {

  const [fields, setFields] = useState({
    "email": {
      "placeholder": "Email",
      "validations": ["required", "string", "email", "trim", "min:3", "max:255"],
      "value": ""
    }
  });

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");

  const submit = async (e) => {
    e.preventDefault();
    let [data, errors] = validate(fields);

    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      setMessage("");
      return;
    }

    data["site"] = "atsoc";

    const {status, result} = await post("newsletter", data);
    switch (status) {
      case 200:
        setErrors({});
        setMessage(result.message);
        break;
      case 400:
        setErrors(result.errors);
        setMessage("");
        break;
      default:
        console.log("Server error " + status);
    }
  }

  return (
      <div className="MailingForm">
        <div>
          <h1>Subscribe</h1>
          <p>to our mailing list for updates on ATSOC's products and services</p>
        </div>
        <form autoComplete="off">
          <div>
            <input type="text" placeholder="Email" autoComplete="new-password" onChange={e => setFields({...fields, "email": {...fields["email"], "value": e.target.value}})}/>
            <button onClick={e => submit(e)}>Sign up</button>
          </div>
          <p>{errors["email"] || message}</p>
        </form>
      </div>
  );
};

export default MailingForm;