import './App.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Company from "./pages/Company";
import Contact from "./pages/Contact";
import Legal from "./pages/legal/Legal";
import Terms from "./pages/legal/Terms";
import PDPA from "./pages/legal/PDPA";
import XdrSoc from "./pages/capabilities/XdrSoc";
import VaPtVms from "./pages/capabilities/VaPtVms";
import Observability from "./pages/capabilities/Observability";
import Financial from "./pages/solutions/Financial";
import Healthcare from "./pages/solutions/Healthcare";
import Systems from "./pages/solutions/Systems";
import Software from "./pages/solutions/Software";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/company" element={<Company />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/terms-of-use" element={<Terms />} />
          <Route path="/personal-data-protection-policy" element={<PDPA />} />
          <Route path="/capabilities/xdr-soc" element={<XdrSoc />}/>
          <Route path="/capabilities/va-pt-vms" element={<VaPtVms />}/>
          <Route path="/capabilities/observability" element={<Observability />}/>
          <Route path="/solutions/finance" element={<Financial />}/>
          <Route path="/solutions/healthcare" element={<Healthcare />}/>
          <Route path="/solutions/systems" element={<Systems />}/>
          <Route path="/solutions/software" element={<Software />}/>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
