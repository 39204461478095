import React, {useEffect} from 'react';
import "./PDPA.scss";

const PDPA = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
      <div className="PDPA">
        <h1>Personal Data Protection Policy</h1>
        <p>This Personal Data Protection Policy ("Policy") sets out the basis which INFINITY CYBERSEC PTE. LTD. and its subsidiaries ("we" or "us" or "our") may collect, use, disclose or otherwise process your personal data in accordance with the Personal Data Protection Act 2012 of Singapore ("PDPA").</p>
        <h2>Collection, Use and Disclosure</h2>
        <p>1. In this Policy, unless the context provides otherwise, "personal data" means data, whether true or not, about you which can be identified: (a) from that data; or (b) from that data and other information to which we have or are likely to have access. Examples of such personal data include:</p>
        <ol type="a">
          <li>Your name, job title, company, address, phone number and email address;</li>
          <li>Photographs and other audio-visual information; and</li>
          <li>Information relating to your access and use of the solutions, products and/or services that we provide, such as login and usage details.</li>
        </ol>
        <p>"you" or "yours" means an individual who (a) has contacted us through any means to find out more about any goods or services we provide, or (b) may, or has, entered into a contract with us for the supply of any goods or services by us.</p>
        <p>2. Generally, we do not collect your personal data. The only personal data about you that we collect is personal data you or your authorised representatives provide or make available to us after having been notified of the purposes for which the personal data is collected and which you or your authorised representatives have consented to the collection, usage and disclosure of the personal data for such purposes. We collect certain personal data from publicly available sources.</p>
        <p>3. By engaging with us, you hereby agree to the collection, use, disclosure and/or processing of your personal data by us and/or our related corporations and affiliates for any or all of the following purposes:</p>
        <ol type="a">
          <li>Performing obligations in the course of or in connection with our provision of solutions, products and/or services to you;</li>
          <li>Verifying your identity;</li>
          <li>Responding to, handling and processing enquiries, requests, applications, complaints and feedback from you;</li>
          <li>Managing our relationship with you;</li>
          <li>Processing payments;</li>
          <li>Security purposes;</li>
          <li>Sending you notices, information, promotions and updates including marketing and advertising materials in relation to our solutions, products and services and those of third-party organisations selected by us;</li>
          <li>Operating our website, customising your browsing experience, conducing analytics and metrics about visitors to our website and other media;</li>
          <li>Conducting research, analysis and development activities (including, but not limited to, data analytics, surveys, product and service development and/or profiling);</li>
          <li>Responding to legal processes or to comply generally with any applicable laws, governmental or regulatory requirements of any relevant jurisdiction (and any applicable rules, codes of practice or guidelines), including, without limitation, meeting the requirements to make disclosure under the requirements of any applicable law or assisting in law enforcement and investigations by relevant authorities;</li>
          <li>Any other purposes for which you have provided the information; and</li>
          <li>All other incidental business purposes related to or in connection with the above.</li>
        </ol>
        <p>4. When you submit your personal data to us through the various forms on our website, you agree to the collection, use, disclosure and/or processing of such personal data by us and/or our related corporations and affiliates for the purposes selected or such other purposes specified by you in these forms.</p>
        <p>5. In the event that you send us personal data in connection with your application for a job or a role with us, you agree to the collection, use, disclosure and/or processing of such personal data by us (and/or where relevant, our related corporations and affiliates) for the following purposes and we may disclose your personal data to third parties where necessary for the following purposes:</p>
        <ol>
          <li>Assessing and evaluating your suitability for employment in any current or prospective position within the organisation; and</li>
          <li>Verifying your identity and the accuracy of your personal details and other information provided.</li>
        </ol>
        <p>6. We operate primarily in Singapore, but through our related corporations and affiliates we may operate in other territories from time to time, such as Malaysia and Indonesia. You consent to the collection, use, disclosure and/or processing of your personal data by us and/or our related corporations and affiliates in accordance with this Policy in these other territories.</p>
        <p>7. We will take reasonable steps to ensure that any transfers of personal data to a territory outside of Singapore will be in accordance with the PDPA to ensure a standard of protection to personal data so transferred that is comparable to the protection under the PDPA. This would include a transfer to any of our related corporations or affiliates which are operating outside of Singapore.</p>
        <p>8. We do not disclose personal data to third parties except when required by law, when we have the individual's consent or deemed consent, or in cases where we have engaged third parties such as data intermediaries or subcontractors specifically to assist with our business. Any such third parties whom we engage will be bound contractually to keep all information confidential.</p>
        <p>9. We will make a reasonable effort to ensure that personal data collected by us is accurate and complete.</p>
        <p>10. The purposes listed in the above clauses may continue to apply even in situations where your relationship with us (for example, pursuant to your employment contract should you be hired) has been terminated or altered in any way, for a reasonable period thereafter (including, where applicable, a period to enable us to enforce our rights under a contract with you).</p>
        <h2>Retention of Information</h2>
        <p>11. Your personal data will be retained for as long as it is necessary to fulfil the purpose for which it is collected or as required or permitted by applicable laws.</p>
        <h2>Security</h2>
        <p>12. We will make reasonable security arrangements to protect personal data that is in our possession or under our control and to prevent unauthorised access, collection, use, disclosure, copying, modification, disposal or similar risks. Nevertheless, please be aware that no method of transmission over the Internet or method of electronic storage is completely secure.</p>
        <h2>Access and Correction Requests</h2>
        <p>13. Upon your written request to privacy@icyber.com for (i) access to a copy of the personal data which we hold about you or information about the ways in which we use or disclose your personal data, or (ii) the correction or update of any of your personal data which we hold about you, we will subject to and in accordance with the requirements of the PDPA (as the case may be):</p>
        <ol type="a">
          <li>Provide you with an account of your personal data which is in our possession or under our control; or</li>
          <li>Correct an error or omission in, or update, your personal data that is in our possession or control.</li>
        </ol>
        <p>We reserve the right to charge a reasonable administrative fee for processing requests for access. If so, we will inform you of the fee before processing your request.</p>
        <p>14. Should we not be able to respond to your request within thirty (30) days after receiving your request, we will inform you in writing within thirty (30) days of the time by which we will be able to respond to your request. If we are unable to provide you with any personal data or to make a correction requested by you, we shall generally inform you of the reasons why we are unable to do so (except where we are not required to do so under the PDPA).</p>
        <h2>Withdrawal of Consent</h2>
        <p>15. The consent that you provide for the collection, use and disclosure of your personal data will remain valid until such time it is being withdrawn by you in writing. If you wish to withdraw your consent for our collection, use or disclose of your personal data, please submit your request in writing to the data protection officer at privacy@icyber.com.</p>
        <p>16. Please allow a reasonable time (depending on the complexity of the request and its impact on our relationship with you) for your request to be processed and for us to notify you of the consequences of us acceding to your request, including any legal consequences which may affect your rights and liabilities to us. Whilst we respect your decision to withdraw your consent, please note that depending on the nature and scope of your request, we may not be in a position to continue providing our solutions, products or services to you and we shall, in such circumstances, notify you before completing the processing of your request.</p>
        <p>17. We will cease (and cause any of our data intermediaries and agents to cease) collecting, using or disclosing the personal data unless it is required or authorised under applicable laws.</p>
        <h2>Do not Call (DNC) Provisions</h2>
        <p>18. We will not send any marketing messages or make any marketing calls to you if you have registered your Singapore telephone number with the respective DNC registries, unless we can do so under the PDPA.</p>
        <h2>Changes to this Policy</h2>
        <p>19. We reserve the right to modify or amend this Policy from time to time without prior notice.</p>
        <h2>Cookies and Linked Websites</h2>
        <p>20. We use temporary or permanent cookies, beacons, tags, scripts and other similar technologies ("cookies") on our websites to identify visitors, enable and track website navigation, gather demographic information including IP addresses, understand visitor and user interest and engagement, understand email and electronic campaign effectiveness, and to increase visitor and user engagement by tracking your activities on our website. While you may turn off, limit or control our cookies, you accept that this may worsen your overall user experience on our websites.</p>
        <p>21. Our websites and services may provide links to other third-party websites and services which are outside our control and not covered by this Policy. We encourage you to review the privacy polices posted on these (and all) sites you visit or services you use.</p>
        <h2>Further Questions</h2>
        <p>22. If you have any queries or comments on this Policy or the collection, use or disclosure of your personal data, please contact the data protection officer at privacy@icyber.com.</p>
      </div>
  );
};

export default PDPA;