import React, {useEffect, useRef, useState} from 'react';
import "./Services.scss";
import PdpaLogo from "../images/pdpa-logo.png";
import NistLogo from "../images/nist-logo.jpg";
import HippaLogo from "../images/hippa-logo.jpg";
import PciLogo from "../images/pci-logo.png";
import GlbaLogo from "../images/glba-logo.jpg";
import FfiecLogo from "../images/ffiec-logo.png";
import GdprLogo from "../images/gdpr-logo.webp";
import {useSearchParams} from "react-router-dom";
import Banner from "../components/Banner";

const Services = () => {

  const [searchParams] = useSearchParams();
  const sections = {
    "monitoring": useRef(),
    "managed": useRef(),
    "incident": useRef(),
    "network": useRef(),
    "cloud": useRef(),
    "automation": useRef(),
    "governance": useRef(),
    "consultancy": useRef(),
    "maintenance": useRef()
  }

  const getCurrentTime = () => {
    const date = new Date();
    return date.getHours() + ":" + String(date.getMinutes()).padStart(2, "0") + ":" + String(date.getSeconds()).padStart(2, "0");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => setTime(getCurrentTime));
    return () => {
      clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    const section = searchParams.get("section");
    if (section) {
      setTimeout(() => sections[section].current.scrollIntoView({behavior: "smooth"}), 1)
    }
  }, [searchParams])

  const [time, setTime] = useState(getCurrentTime());

  const getLoopClass = (length, index, name) => {
    return (parseInt(time.substring(time.length - 2)) % length) === index ? name : "";
  }

  const getLoopClassStacked = (length, index, name) => {
    return (parseInt(time.substring(time.length - 2)) % length) >= index ? name : "";
  }

  return (
      <div className="Services">
        <Banner>
          <div className="banner-item services">
            <h2>Services</h2>
            <p>Explore the services of ATSOC provided by our experienced team of cybersecurity professionals capable in designing and implementing security solutions that are most suitable for your organisation.</p>
          </div>
        </Banner>
        <div className="main">
          <div ref={sections["monitoring"]}>
            <div>
              <h1>24/7 Monitoring</h1>
              <p>ATSOC provides 24/7 monitoring for cyber threats and alerts the incident response team as soon as a suspicious activity appears. Our systems ensure that potential cyber attacks are prevented at all costs. Timely threat detection helps organisations protect their assets and uphold their reputation.</p>
            </div>
            <div className="monitoring-graphic">
              <div>
                <div className={(parseInt(time.substring(time.length - 1)) % 2) === 0? "green": ""}></div>
                <p>Monitoring</p>
              </div>
              <h1>{time}</h1>
            </div>
          </div>
          <div ref={sections["managed"]}>
            <div>
              <h1>Managed Services</h1>
              <p>Our Security Operations Centre provides an overview of all endpoints and services within the IT environment. Monitoring of statuses, network traffic and security events continues in an ongoing basis to ensure consistent detection, monitoring and management of threats. We are happy to provide customised regular event reports based on your monitoring and reporting requirements.</p>
            </div>
            <div className="managed-graphic">
              <h1>Security Operations Centre</h1>
              <div>
                <div className={getLoopClass(4, 0, "color")}>
                  <p>Servers</p>
                </div>
                <div className={getLoopClass(4, 1, "color")}>
                  <p>Endpoints</p>
                </div>
                <div className={getLoopClass(4, 3, "color")}>
                  <p>Network</p>
                </div>
                <div className={getLoopClass(4, 2, "color")}>
                  <p>Alerts</p>
                </div>
              </div>
            </div>
          </div>
          <div ref={sections["incident"]}>
            <div>
              <h1>Incident Response</h1>
              <p>With the increasing frequency of cyber attacks these days, even the most secured organisations have the potential to be targeted and breached. ATSOC’s security team remains ready and capable of providing the most efficient services that are appropriate in response to any attacks. Depending on the specific incident, we will perform investigation, escalation, threat triage and recovery to minimise losses to the attack. Our playbooks and runbooks are continually updated to cover the latest cyber incidents.</p>
            </div>
            <div className="incident-graphic">
              <p className={getLoopClass(5, 0, "color")}>IDENTIFY</p>
              <p className={getLoopClass(5, 1, "color")}>PROTECT</p>
              <p className={getLoopClass(5, 2, "color")}>DETECT</p>
              <p className={getLoopClass(5, 3, "color")}>RESPOND</p>
              <p className={getLoopClass(5, 4, "color")}>RECOVER</p>
            </div>
          </div>
          <div ref={sections["network"]}>
            <div>
              <h1>Network Monitoring</h1>
              <p>ATSOC’s network monitoring solutions ensures that network health and security remain operational and protected against potential cyber-attacks. Through monitoring and analysis of network logs collected from network devices, we are able to identify suspicious and abnormal traffic based on a range of signals including behaviour, frequency and geographical location. When such anomalous activities are detected, further investigation can be initiated to determine the nature of the incident and perform containment and recovery plans if warranted.</p>
            </div>
            <div className="network-graphic">
              <div className={getLoopClass(4, 0, "color")}>
                <span className="material-icons">&#xe002;</span>
                <p>Device xxx logged in from unknown location</p>
              </div>
              <div className={getLoopClass(4, 1, "color")}>
                <span className="material-icons">&#xe002;</span>
                <p>Device xxx sent too many requests within 1 minute</p>
              </div>
              <div className={getLoopClass(4, 2, "color")}>
                <span className="material-icons">&#xe002;</span>
                <p>Unknown device attempting to connect to port 3386 on server xxx</p>
              </div>
              <div className={getLoopClass(4, 3, "color")}>
                <span className="material-icons error">&#xe000;</span>
                <p>Device xxx connected to identified malicious site</p>
              </div>
            </div>
          </div>
          <div ref={sections["cloud"]}>
            <div>
              <h1>Cloud Security</h1>
              <p>The extensive growth in cloud computing has provided organisations with transformational opportunities in flexible and scalable deployment that rely on virtualised assets that can be distributed and extended remotely without the costs and complications of acquiring and maintaining physical hardware. However, security risks associated with data and application remains relevant with cloud deployments, and it is important to implement a solution that provides the same level of security as a traditional datacentre deployment.</p>
              <p>ATSOC’s cloud security capabilities provide effective managed detection and response that covers cloud and hybrid cloud environments. Through log data collection and security events monitoring, we provide comprehensive protection against potential vulnerabilities and cyberattacks on our clients’ virtual infrastructure to ensure security for data and applications.</p>
            </div>
            <div className="cloud-graphic">
              <span className="material-icons">&#xe2bd;</span>
              <div className="line">
                <div className="circle"></div>
              </div>
              <span className="material-icons error">&#xe30a;</span>
            </div>
          </div>
          <div ref={sections["automation"]}>
            <div>
              <h1>Automation</h1>
              <p>With a growing range and quantity of devices managed by organisations, automation has become essential in providing efficient and effective security deployments. With ATSOC’s automation processes, we are able to ensure that security deployments are performed systematically and reliably, identify and rectify misconfigurations, as well as provide timely and regular updates. </p>
            </div>
            <div className="automation-graphic">
              <div>
                <div className={getLoopClassStacked(6, 1, "color")}>
                  <span className="material-icons">&#xf091;</span>
                  <p>Endpoint 01</p>
                </div>
                <div className={getLoopClassStacked(6, 2, "color")}>
                  <span className="material-icons">&#xf091;</span>
                  <p>Endpoint 02</p>
                </div>
                <div className={getLoopClassStacked(6, 3, "color")}>
                  <span className="material-icons">&#xf091;</span>
                  <p>Endpoint 03</p>
                </div>
                <div className={getLoopClassStacked(6, 4, "color")}>
                  <span className="material-icons">&#xf091;</span>
                  <p>Endpoint 04</p>
                </div>
                <div className={getLoopClassStacked(6, 5, "color")}>
                  <span className="material-icons">&#xf091;</span>
                  <p>Endpoint 05</p>
                </div>
              </div>
            </div>
          </div>
          <div ref={sections["governance"]}>
            <div>
              <h1>Governance and Compliance</h1>
              <p>ATSOC is committed in ensuring compliance to any governance policies that apply to specific business of the organisation. In addition to the standard practices in data, application and network security, ATSOC also implements policies from industry leading standards in accordance with the requirements of the organisation, including:</p>
              <ul>
                <li>Personal Data Protection Act (PDPA)</li>
                <li>NIST Cybersecurity Framework (CSF)</li>
                <li>Health Insurance Portability and Accountability Act (HIPPA)</li>
                <li>Payment Card Industry and Data Security Standard (PCI)</li>
                <li>GLBA/FFIEC</li>
                <li>General Data Protection Regulation (GDPR)</li>
              </ul>
            </div>
            <div className="governance-graphic">
              <div>
                <img src={PdpaLogo} alt="" className={getLoopClass(7, 0, "color")}/>
                <img src={NistLogo} alt="" className={getLoopClass(7, 1, "color")}/>
                <img src={HippaLogo} alt="" className={getLoopClass(7, 2, "color")}/>
                <img src={PciLogo} alt="" className={getLoopClass(7, 3, "color")}/>
                <img src={GlbaLogo} alt="" className={getLoopClass(7, 4, "color")}/>
                <img src={FfiecLogo} alt="" className={getLoopClass(7, 5, "color")}/>
                <img src={GdprLogo} alt="" className={getLoopClass(7, 6, "color")}/>
              </div>
            </div>
          </div>
          <div ref={sections["consultancy"]}>
            <div>
              <h1>Consultancy</h1>
              <p>ATSOC’s consultancy services helps organisations plan their cybersecurity implementations to protect against risks from both internal and external threats. With a team of consultants with substantial experience planning and implementing security solutions for a large range of organisations, we provide customised recommendations that are designed specific to your organisational requirements, infrastructure, risk tolerance and cost.</p>
            </div>
            <div className="consultancy-graphic"></div>
          </div>
          <div ref={sections["maintenance"]}>
            <div>
              <h1>Maintenance</h1>
              <p>Maintenance of security infrastructure post deployment is essential in ensuring continued operability and effectiveness of the implemented security solutions. ATSOC’s maintenance processes continuously monitors the operations of security software and appliance deployed, providing immediate support when required. To secure against zero-day vulnerabilities, ATSOC ensures that security patches and updates released are deployed as soon as possible, while reducing downtime of business operations and verifies the compatibility of the updates with existing installations.</p>
            </div>
            <div className="maintenance-graphic">
              <h1>Patch Management</h1>
              <div className={getLoopClassStacked(5, 1, "color")}>
                <p>Identify systems </p>
                <p>that are non-compliant, vulnerable or unpatched</p>
              </div>
              <div className={getLoopClassStacked(5, 2, "color")}>
                <p>Prioritise patches</p>
                <p>based on impact, risk and downtime</p>
              </div>
              <div className={getLoopClassStacked(5, 3, "color")}>
                <p>Patch regularly</p>
                <p>to ensure up-to-date security</p>
              </div>
              <div className={getLoopClassStacked(5, 4, "color")}>
                <p>Test patches</p>
                <p>before deployment to production</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Services;