import React, {useEffect, useState} from 'react';
import validate from "../utilities/Validator";
import "./ContactForm.scss";
import {post} from "../utilities/Api";
import {FormControlLabel, RadioGroup, Radio} from "@material-ui/core";
import {useSearchParams} from "react-router-dom";

const ContactForm = () => {

  const [searchParams] = useSearchParams();
  const [formType, setFormType] = useState("atsoc-" + (searchParams.get("form") || "general"));
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [fields, setFields] = useState({
        "form": {
          "type": "custom",
          "id": "form"
        },
        "first_name": {
          "placeholder": "First Name",
          "validations": ["required", "string", "trim", "max:255"],
          "value": ""
        },
        "last_name": {
          "placeholder": "Last name",
          "validations": ["required", "string", "trim", "max:255"],
          "value": ""
        },
        "email": {
          "placeholder": "Email",
          "validations": ["required", "string", "email", "trim", "min:3", "max:255"],
          "value": ""
        },
        "phone": {
          "placeholder": "Phone",
          "validations": ["required", "string", "phone", "trim"],
          "value": ""
        },
        "company": {
          "placeholder": "Company",
          "validations": ["required", "string", "trim", "max:255"],
          "value": ""
        }
  });

  useEffect(() => {
    setFormType("atsoc-" + (searchParams.get("form") || "general"));
  }, [searchParams])

  const getFormElement = (id) => {
    if (id === "form")
      return (
          <RadioGroup
              value={formType}
              onChange={e => setFormType(e.target.value)}
              className="form-type"
          >
            <FormControlLabel value="atsoc-general" control={<Radio />} label="General Enquiries" />
            <FormControlLabel value="atsoc-demo" control={<Radio />} label="Request a Demo" />
            <FormControlLabel value="atsoc-health-check" control={<Radio />} label="Free Cyber Health Check" />
          </RadioGroup>
      )
  }

  const submit = async (e) => {
    e.preventDefault();
    let [data, errors] = validate(fields);

    // Debug log
    // console.log(data);
    // console.log(errors);

    // Check for errors
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      setMessage("");
      return;
    }

    data["form"] = formType;
    const {status, result} = await post("booking", data);
    switch (status) {
      case 200:
        setErrors({});
        setMessage(result.message);
        break;
      case 400:
        setErrors(result.errors);
        setMessage("");
        break;
      default:
        console.log("Server error " + status);
    }

  }

  return (
      <div className="ContactForm">
        <h1>Contact us</h1>
        <form autoComplete="off">
          {Object.entries(fields).map(([field, properties]) => properties["type"] !== "custom" ? (
              <div key={field}>
                <input type="text" autoComplete="new-password" placeholder={properties["placeholder"]} onChange={e => setFields({...fields, [field]: {...fields[field], "value": e.target.value}})}/>
                <p className="error">{errors[field]}</p>
              </div>
          ) : getFormElement(properties["id"]))}
          <div>
            <button onClick={e => submit(e)}>Submit</button>
            <p>{message}</p>
          </div>
        </form>
      </div>
  );
};

export default ContactForm;